import { fetchMessages } from "./ChatActions";

export function getUserToken() {
    const token = sessionStorage.getItem("token");
    return token;
}

export function getSessionID() {
    const session_id = sessionStorage.getItem("session_id");
    return session_id;
}

export function setUserToken(token) {
    return sessionStorage.setItem("token", token);
}

export async function getMessages(offset = null) {
    const messages = await fetchMessages(getUserToken(), getSessionID(), offset);
    return messages;
}

export function getMessageBlock(messageObj) {
    return {
        'bot': {
            'text': messageObj['bot'] || null,
            'suggested_questions': messageObj['suggested_questions'] || null,
            'disclaimer': messageObj['disclaimer'] || null,
            'sources': messageObj['sources'] || null,
            'take_feedback': messageObj['take_feedback'] || null,
            'feedback': messageObj['feedback'] || null,
            'welcome': messageObj['welcome'] || null,
            'error': messageObj['error'] || null
        },
        'user': {
            'text': messageObj['user']['query'] || null,
            'welcome': messageObj['welcome'] || null,
            'image': messageObj['image'] || null
        }
    }   
}


export function getBotMessageBlock(messageObj) {
    return {
        'bot': {
            'text': messageObj['bot'] || null,
            'suggested_questions': messageObj['suggested_questions'] || null,
            'disclaimer': messageObj['disclaimer'] || null,
            'sources': messageObj['sources'] || null,
            'take_feedback': messageObj['take_feedback'] || null,
            'feedback': messageObj['feedback'] || null,
            'welcome': messageObj['welcome'] || null,
            'error': messageObj['error'] || null
        }
    }  
}

export function getUserMessageBlock(input, defaultFlag, image) {
    return {
        'user': {
              'text': input,
              'welcome': defaultFlag || null,
              'image': image
        }
    }
}


export function getProcessedCustomPayload(project, inputText, selectedLanguageCode, customQueryFlag, image = null) {
    let payload =  {
        "values":[
            {
                "value":inputText,
                "type":"text"
            }
        ],
        "lang": selectedLanguageCode,
        "image": image,
        "custom_query_flag": customQueryFlag,
    }
    if (project === 'GHS') {
        if (inputText !== localStorage.getItem('selectedDocument')) {
            payload["documents_to_search"] = localStorage.getItem('selectedDocument').split(',')
        }
        else {
            payload["documents_to_search"] = null
        }
    }
    return payload
}

export const stopPlayingAudio = (audioRef) => {
    if (audioRef.current) {
        audioRef.current.pause();
      }
}