import { useEffect, useState } from "react";
import "./LoginComponent.css";
import NextBtn from "../../../../Shared/Components/NextBtn/NextBtn";
import { useNavigate } from "react-router-dom";
import { getUserData, login } from "../../../../Actions/UserActions";
import { getPollyToken } from "../../../../Actions/ChatActions";
import { stopPlayingAudio } from "../../../../Actions/Utils";
import { generateSpeech } from "../../../../Actions/ChatActions";

const LoginComponent = ({ project, selectedLanguage, audioRef }) => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const guide = localStorage.getItem("onboarding");
  const consent = localStorage.getItem("consent");
  const [loginError, setLoginError] = useState("");
  const [showErrorFlag, setShowErrorFlag] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const [showSpeakerIcon, setShowSpeakerIcon] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(false);
  const [requestController, setRequestController] = useState(null);
  const [loginConstants, setLoginConstants] = useState(null);
  const [guideConstants, setGuideConstants] = useState(null);
  const skipOnboardingFlag = process.env.REACT_APP_SKIP_ONBOARDING === 'true';
  useEffect(() => {
    const loadConstants = async () => {
      const loginModule = await import(`../../../../Constants/${project}/LoginConstant`);
      const guideModule = await import(`../../../../Constants/${project}/GuideConstant`);
      setLoginConstants(loginModule);
      setGuideConstants(guideModule);
    };

    loadConstants();

    stopPlayingAudio(audioRef);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const goToNext = () => {
    console.log("skipOnboardingFlag", skipOnboardingFlag)
    if (skipOnboardingFlag){
      navigate("/consent");
    }
    else {
      navigate("/guide");
    }
  };
  const backArrowClick = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleAudio = async () => {
      const url = await generateSpeech(
        loginAudio[selectedLanguage],
        selectedLanguage
      );
      const newAudio = new Audio(url);
      if (audioRef.current) {
        audioRef.current.pause();
      }
      if (requestController) {
        requestController.abort();
      }

      newAudio.addEventListener("ended", () => {
        setDisableButtons(false);
      });

      audioRef.current = newAudio;
      newAudio.play().catch((err) => {
        console.error("Error playing audio:", err);
      });
    };
    if (showSpeakerActive) handleAudio();
    setTimeout(() => {
      setDisableButtons(false);
    }, 9000);
  }, [showSpeakerActive]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  const nextClick = async () => {
    const res = await login(mobileNumber, password);
    // Check for errors
    if (res["error"]) {
      setLoginError(res["error"]);
      setShowErrorFlag(true);
    } else {
      // Check for login token
      if (res["token"]) {
        sessionStorage.setItem("token", res["token"]);
        res["data"]["mobile"] = mobileNumber;
        sessionStorage.setItem("userProfileData", JSON.stringify(res["data"]));
        sessionStorage.setItem("session_id", res["session_id"]);
        goToNext();
      }
    }
  };

  const handleMobileChange = (e) => {
    setLoginError(null);
    const value = e.target.value;
    // Check if the entered value is numeric and not exceeding 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
    }
  };

  const handlePasswordChange = (e) => {
    setLoginError(null);
    const value = e.target.value;
    // Check if the entered value is numeric and not exceeding 4 digits
    if (/^\d{0,4}$/.test(value)) {
      setPassword(value);
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  if (!loginConstants || !guideConstants) {
    return <div>Loading...</div>;
  }

  const { loginConstant, loginErrorLabels, loginErrorMap, loginAudio } = loginConstants;
  const { guideButtons } = guideConstants;

  return (
    <div className="loginComponent">
      <div className="flex w-[100%] flex-col items-center justify-center px-4 pt-2">
        <div className="flex items-center justify-between w-full">
          <div className="loginNextBtn   ">
            <NextBtn
              icon={"/Assets/Images/arrowLeftBlack.svg"}
              nextClick={backArrowClick}
            />


          </div>
          <div
            className={`speakerDiv ${
              showSpeakerIcon && showSpeakerActive ? "Active" : ""
              }`}
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
              stopPlayingAudio(audioRef);
              setDisableButtons(false);
            }}
          >
            {showSpeakerIcon && showSpeakerActive
              ? guideButtons.stop[selectedLanguage]
              : null}
            {showSpeakerActive ? (
              <div className="">
                <img
                  src={"/Assets/Images/blueSpeaker.svg"}
                  alt="active Speaker"
                />
              </div>
            ) : (
              <div className="">
                <img src={"/Assets/Images/crossSpeakerIcon.svg"} />
              </div>
            )}
          </div>


        </div>

        <div className="loginAshabox">
          {project != "GHS" && <img
            src={"/Assets/Images/" + project + "/AppIcon.svg"}
            className="h-16 w-16 rounded-full"
            alt="Asha"
          />}
        </div>
        {/* <div
          className={`speakerDiv ${
            showSpeakerIcon && showSpeakerActive ? "Active" : ""
          }`}
          onClick={() => {
            setShowSpeakerActive(!showSpeakerActive);
            stopPlayingAudio(audioRef);
            setDisableButtons(false);
          }}
        >
          {showSpeakerIcon && showSpeakerActive
            ? guideButtons.stop[selectedLanguage]
            : null}
          {showSpeakerActive ? (
            <div className="">
              <img
                src={"/Assets/Images/blueSpeaker.svg"}
                alt="active Speaker"
              />
            </div>
          ) : (
            <div className="">
              <img src={"/Assets/Images/crossSpeakerIcon.svg"} />
            </div>
          )}
        </div> */}

        {/* <div>
          <img src={"/Assets/Images/crossSpeakerIcon.svg"} alt="Speaker" />
        </div> */}
      </div>
      <div className="loginInputComponent">
        <div className="flex flex-col gap-2">
          <div className="flex gap-1.5 items-center">
            <img src={"/Assets/Images/mobileIcon.svg"} alt="mobile" />
            <label>{loginConstant.mobile[selectedLanguage]}</label>
          </div>
          <input
            className={`inputfield focus:ring-blue-500 focus:border-blue-500 border ${
              loginError == loginErrorMap.username
                ? "border-[var(--red-1)]"
                : "border-[var(--dark-neutral-1)]"
              }`}
            autoFocus
            type="tel"
            placeholder="1234567890"
            required
            maxLength={10}
            value={mobileNumber}
            onChange={handleMobileChange}
          />
          {loginError == loginErrorMap.username && (
            <div className="flex items-center text-[var(--red-1)]">
              <img src="./Assets/Images/alertCircle.svg" />
              <span className="pl-2 text-lg">
                {loginErrorLabels.username[selectedLanguage]}
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 items-center">
            <img src={"/Assets/Images/passwordIcon.svg"} alt="password" />
            <label>
              {loginConstant.pass[selectedLanguage]}{" "}
              <span className="text-[var(--dark-neutral-2)]">
                {loginConstant.passSpan[selectedLanguage]}
              </span>
            </label>
          </div>
          <input
            className={`inputfield focus:ring-blue-500 focus:border-blue-500 border ${
              loginError == loginErrorMap.password
                ? "border-[var(--red-1)]"
                : "border-[var(--dark-neutral-1)]"
              }`}
            type="tel"
            placeholder="0000"
            required
            maxLength={4}
            value={password}
            onChange={handlePasswordChange}
          />
          {loginError == loginErrorMap.password && (
            <div className="flex items-center text-[var(--red-1)]">
              <img src="./Assets/Images/alertCircle.svg" />
              <span className="pl-2 text-lg">
                {loginErrorLabels.password[selectedLanguage]}
              </span>
            </div>
          )}
        </div>
      </div>
      {mobileNumber.length === 10 && password.length === 4 && (
        <div className="loginNextBtn">
          <NextBtn
            value={loginConstant.continueButton[selectedLanguage]}
            icon={"/Assets/Images/nextWhiteIcon.svg"}
            bgColor={"var(--primary-dark)"}
            color={"var(--neutral-white)"}
            nextClick={nextClick}
            padx={48}
          />
        </div>
      )}
      <div className="loginSupport">
        <img src={"/Assets/Images/supportIcon.svg"} alt="support" />
        {loginConstant.support[selectedLanguage]}
      </div>
    </div>
  );
};

export default LoginComponent;
