import { useEffect, useState } from "react";
import "./NavBar.css";

const NavBar = ({ project, selectedLanguage, textSize, toggleMenu = () => {}, toggleHelp = () => {} }) => {
  const [constants, setConstants] = useState(null);
  const hideHeaderIconFlag = process.env.REACT_APP_HIDE_HEADER_ICON === 'true';
  useEffect(() => {
    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/NavConstants`);
      setConstants(module);
    }

    loadConstants();
  }, []); 

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { navTitle } = constants;

  return (
    <div className="flex justify-between items-center pt-2 px-4 z-0 navBarDiv">
      <div onClick={toggleMenu}>
        <img src={"/Assets/Images/NavBar.svg"} alt="Navbar" />
      </div>
      <div className={`font-semibold flex items-center justify-center gap-2.5 py-4 nameBarDiv`} style={{fontSize: `${textSize}rem`}}>
        {!hideHeaderIconFlag &&<img className="h-10 w-10 rounded-full" src={"/Assets/Images/" + project + "/AppIcon.svg"} alt='Asha'/>}
        {navTitle[selectedLanguage]}
      </div>
      <div >
        <img src={"/Assets/Images/helpCircle.svg"} alt="Help" />
      </div>
    </div>
  );
};

export default NavBar;
