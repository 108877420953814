const DefaultChips = ({
    option,
    selectedLanguage,
    send = () => {},
    textSize,
    disabled,
    index,
    selectedChipIndex, 
    isLoading,
    onChipClick 
  }) => {
    function saveResponse(input, image) {
      send(index, input, image);
    }
  
    return (
      <div
        className={`flex items-center justify-center rounded-lg border border-solid border-1 border-[var(--primary-dark)]  text-[var(--primary-dark)] bg-[var(--neutral-white)]  min-h-[3rem] ${isLoading ? 'pointer-events-none' : ''}`}
          onClick={() => {
              onChipClick(option.value[selectedLanguage]);
              saveResponse(option.value[selectedLanguage], option.img);
            }}
        style={{ fontSize: `${textSize}rem` }}
      >
        {option.img && <img
            src={option.img}
            alt={option.value[selectedLanguage]}
            className="h-[100%]"
          />}
        <div className="m-2 truncate">
          {option.value[selectedLanguage]}
        </div>
      </div>
    );
  };
  
  export default DefaultChips;
  