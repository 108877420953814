import QuestionChips from "../QuestionChips/QuestionChips";
import OptionChips from "../OptionChips/OptionChips";
import DisclaimerChatBlock from "../DisclaimerChatBlock/DisclaimerChatBlock";
import StarChatBlock from "../StarChatBlock/StarChatBlock";
import SpeakerChatBlock from "../SpeakerChatBlock/SpeakerChatBlock";
import FeedbackChatBlock from "../FeedbackChatBlock/FeedbackChatBlock";
import SourcesChatBlock from "../SourcesChatBlock/SourcesChatBlock";
import FeedbackButtonBlock from "../FeedbackButtonBlock/FeedbackButtonBlock";
import { useEffect, useState } from "react";
const feedback_same = process.env.REACT_APP_FEEDBACK_SAME === 'false'; 

const BotChatBlock = ({
  project,
  idx,
  followingIdx,
  selectedLanguage,
  item,
  speakerEnabled,
  initSpeechSynth = () => { },
  stopAudio = () => { },
  saveResponse = () => { },
  openSourceDialog = () => { },
  sendFeedback = () => { },
  time,
  textSize,
  disabled,
  markFavourite = () => { }
}) => {
  const [defaultChipsConstants, setDefaultChipsConstants] = useState(null);
  const [feedbackConstants, setFeedbackConstants] = useState(null);
  const [chatConstants, setChatConstants] = useState(null);
  const [errorConstants, setErrorConstants] = useState(null);

  useEffect(() => {
    const loadConstants = async () => {
      const defaultchipmodule = await import(`../../../Constants/${project}/OptionConstants`);
      const feedbackmodule = await import(`../../../Constants/${project}/FeedbackConstants`);
      const chatmodule = await import(`../../../Constants/${project}/ChatConstants`);
      const errormodule = await import(`../../../Constants/${project}/ErrorConstants`);
      setDefaultChipsConstants(defaultchipmodule);
      setFeedbackConstants(feedbackmodule);
      setChatConstants(chatmodule);
      setErrorConstants(errormodule);
    }

    loadConstants();
  }, []);
  
  if (!defaultChipsConstants || !feedbackConstants || !chatConstants || !errorConstants) {
    return <div>Loading...</div>;
  }

  const { DefaultChipsList } = defaultChipsConstants;
  const { FeedbackConstant, FeedbackValues } = feedbackConstants;
  const { anwerTypes, botDisclaimerMessage, defaultChipBlockText, welcomeMessageBlockText, summarizedAnswerTitle, validatedDislikeMessage, validatedSameMessage } = chatConstants;
  const { errorCodeMap, errorMap } = errorConstants;

  const defaultChipMarkup = () => ({ __html: defaultChipBlockText?.html[selectedLanguage] });

  const welcomeMessageMarkup = () => ({ __html: welcomeMessageBlockText?.html[selectedLanguage] });
  return (
    <div className='float-left relative flex flex-col'>
      {/* main message block */}
      {item?.bot?.text && (
        <div className="flex items-center justify-start">
          <div
            className={`relative mb-2 ${speakerEnabled === idx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : item?.bot?.error?.code ? `${'bg-[var(--' + errorMap[item?.bot?.error?.code]["background"] + ')]'} border ${'border-[var(--' + errorMap[item?.bot?.error?.code]["border"] + ')]'}` : item?.bot?.starred ? 'bg-[var(--star-yellow-3)] border border-[var(--star-yellow-2)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[color:var(--almost-black)] leading-5 rounded-t-lg rounded-br-lg`}
            style={{ fontSize: `${textSize}rem` }}
          >
            {!(item?.bot?.error?.code) && <div className="float-right m-3">
              <StarChatBlock idx={idx} id={item?._id} starred={item?.bot?.starred} mark={markFavourite} />
            </div>}
            <div className="mb-8 mt-4 mx-3">
              {item?.bot?.answer_type == anwerTypes.Summarized && <div className={`flex items-center justify-start mb-3 ${item?.bot?.starred ? 'text-[var(--star-yellow-1)]' : 'text-[var(--dark-neutral-2)]'}`}>
                {item?.bot?.starred && <img src={"./Assets/Images/yellowSummarizedIcon.svg"} />}
                {!item?.bot?.starred && <img src={"./Assets/Images/summarizedIcon.svg"} />}
                <span className="pl-2">{summarizedAnswerTitle[selectedLanguage]}</span>
              </div>}
              {item?.bot?.welcome ? <div className='flex flex-col' dangerouslySetInnerHTML={welcomeMessageMarkup()}></div> : item?.bot?.error?.code ? errorMap[item?.bot?.error?.code]["message"][selectedLanguage] : item?.bot?.text[selectedLanguage]}
            </div>
            {item?.bot?.answer_type == anwerTypes.Summarized && (
              <DisclaimerChatBlock
                selectedLanguage={selectedLanguage}
                text={botDisclaimerMessage}
              ></DisclaimerChatBlock>
            )}
          </div>
          <div className="ml-4 flex flex-col gap-3">
            <SpeakerChatBlock
              idx={idx}
              title={item?.bot?.error?.code ? errorMap[item?.bot?.error?.code]["message"][selectedLanguage] : item?.bot?.text[selectedLanguage]}
              options={item?.bot?.options}
              isPlaying={speakerEnabled === idx}
              startAudio={initSpeechSynth}
              stopAudio={stopAudio}
            />
            {item?.bot?.sources?.length > 0 && (
              <SourcesChatBlock
                sourceData={item?.bot?.sources}
                openSourceDialog={openSourceDialog}
              />
            )}
          </div>
        </div>
      )}
      {/* welcome default block */}
      {(project !== 'GHS') && (item?.bot?.welcome || item?.bot?.error?.code == errorCodeMap["No Answer"]) && (
        <div className="flex items-center justify-start">
          <div
            className={`relative mb-2 ${speakerEnabled === followingIdx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[color:var(--almost-black)] leading-5 rounded-t-lg rounded-br-lg`}
            style={{ fontSize: `${textSize}rem` }}
          >
            <div className="mb-8 mt-4 mx-3" dangerouslySetInnerHTML={defaultChipMarkup()}>
            </div>
          </div>
          <div className="ml-4 flex flex-col gap-3">
            <SpeakerChatBlock
              idx={followingIdx}
              title={defaultChipBlockText?.audio[selectedLanguage]}
              options={[]}
              isPlaying={speakerEnabled === followingIdx}
              startAudio={initSpeechSynth}
              stopAudio={stopAudio}
            />
            {item?.bot?.sources?.length > 0 && (
              <SourcesChatBlock
                sourceData={item?.bot?.sources}
                openSourceDialog={openSourceDialog}
              />
            )}
          </div>
        </div>
      )}
      {/* chips block */}
      {item?.bot?.options ? (
        item?.bot?.welcome ? (
          <div className={`mr-6 grid grid-cols-2 grid-rows-2 gap-3 mb-2 ${disabled ? 'pointer-events-none' : ''}`}>
            {DefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                />
              );
            })}
          </div>
        ) : (
          <div className={`ml-4 w-fit max-w-[77.5%] mt-[-0.5rem] ${disabled ? 'pointer-events-none' : ''}`}>
            <QuestionChips
              selectedLanguage={selectedLanguage}
              options={item?.bot?.options}
              saveResponse={saveResponse}
              disabled={disabled}
              selectedChipIndex={item?.bot?.selectedChipIndex}
            />
          </div>
        )
      ) : null}
      {/* bot timestamp */}
      <div className="text-xs text-[color:var(--dark-neutral-2)] px-3 mb-2 mt-2">
        {time}
      </div>
      {/* feedback block */}
      {item?.bot?.take_feedback && (
          <div className={`p-3 mb-2 bg-[var(--neutral-white)] w-fit max-w-[77.5%] text-[16px] text-[color:var(--almost-black)] leading-5 rounded-t-lg rounded-br-lg ${disabled ? 'pointer-events-none' : ''}`}>
            {FeedbackConstant.title[selectedLanguage]}
            {item?.bot?.feedback_type === '' && <FeedbackChatBlock sendFeedback={sendFeedback} selectedLanguage={selectedLanguage}></FeedbackChatBlock>}
          </div>
      )}
      {/* feedback response block */}
      {item?.bot?.feedback_type && (
        <div className={`w-full flex items-center justify-end ${disabled ? 'pointer-events-none' : ''}`}>
          <FeedbackButtonBlock
            feedback={item?.bot?.feedback_type}
            selectedLanguage={selectedLanguage}
          />
        </div>
      )}
      {/* validated suggested questions block */}
      {feedback_same && item?.bot?.suggested_questions != null && item?.bot?.answer_type == anwerTypes.Validated && item?.bot?.feedback_type == FeedbackValues.LIKE && (
        <div>
          <div
            className={`${item["bot"]["welcome"] ? "pb-3" : ""
              } flex items-center justify-start`}
          >
            <div className={`${speakerEnabled === followingIdx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[16px] text-[color:var(--almost-black)] leading-5 rounded-t-lg rounded-br-lg`}>
              <div className={`mb-${item["bot"]["welcome"] ? "4" : "8"} mt-4 mx-3`}>
                {item?.bot?.suggested_questions?.title[selectedLanguage]}
              </div>
            </div>
            <div className="ml-4">
              <SpeakerChatBlock
                idx={followingIdx}
                title={item?.bot?.suggested_questions?.title[selectedLanguage]}
                options={item?.bot?.suggested_questions?.options}
                isPlaying={speakerEnabled === followingIdx}
                startAudio={initSpeechSynth}
                stopAudio={stopAudio}
              />
            </div>
          </div>
          {item?.bot?.suggested_questions?.options ? (
            <div className={`ml-4 w-fit max-w-[77.5%] mt-[-0.5rem] ${disabled ? 'pointer-events-none' : ''}`}>
              <QuestionChips
                selectedLanguage={selectedLanguage}
                options={item?.bot?.suggested_questions?.options}
                saveResponse={saveResponse}
                disabled={disabled}
                selectedChipIndex={item?.bot?.selectedChipIndex}
              />
            </div>
          ) : (
            <div className={`mr-6 grid grid-cols-2 grid-rows-2 gap-3 mb-4 ${disabled ? 'pointer-events-none' : ''}`}>
              {" "}
              {DefaultChipsList.map((option, index) => {
                return (
                  <OptionChips
                    key={option.id}
                    option={option}
                    selectedLanguage={selectedLanguage}
                    saveResponse={saveResponse}
                    disabled={disabled}
                    index={index}
                    selectedChipIndex={item?.bot?.selectedChipIndex}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      {/* summarized suggested questions block */}
      {feedback_same && item?.bot?.suggested_questions != null && item?.bot?.answer_type == anwerTypes.Summarized && item?.bot?.feedback_type == FeedbackValues.DISLIKE && (
        <div>
          <div
            className={`${item["bot"]["welcome"] ? "pb-3" : ""
              } flex items-center justify-start`}
          >
            <div className={`${speakerEnabled === followingIdx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[16px] text-[color:var(--almost-black)] leading-5 rounded-t-lg rounded-br-lg`}>
              {item?.bot?.suggested_questions?.title && <div className={`mb-${item["bot"]["welcome"] ? "4" : "8"} mt-4 mx-3`}>
                {item?.bot?.suggested_questions?.title[selectedLanguage]}
              </div>}
            </div>
            <div className="ml-4">
              <SpeakerChatBlock
                idx={followingIdx}
                title={item?.bot?.suggested_questions?.title[selectedLanguage]}
                options={item?.bot?.suggested_questions?.options}
                isPlaying={speakerEnabled === followingIdx}
                startAudio={initSpeechSynth}
                stopAudio={stopAudio}
              />
            </div>
          </div>
          {item?.bot?.suggested_questions?.options ? (
            <div className={`ml-4 w-fit max-w-[77.5%] mt-[-0.5rem] ${disabled ? 'pointer-events-none' : ''}`}>
              <QuestionChips
                selectedLanguage={selectedLanguage}
                options={item?.bot?.suggested_questions?.options}
                saveResponse={saveResponse}
                disabled={disabled}
                selectedChipIndex={item?.bot?.selectedChipIndex}
              />
            </div>
          ) : (
            <div className={`mr-6 grid grid-cols-2 grid-rows-2 gap-3 mb-4 ${disabled ? 'pointer-events-none' : ''}`}>
              {" "}
              {DefaultChipsList.map((option, index) => {
                return (
                  <OptionChips
                    key={option.id}
                    option={option}
                    selectedLanguage={selectedLanguage}
                    saveResponse={saveResponse}
                    disabled={disabled}
                    index={index}
                    selectedChipIndex={item?.bot?.selectedChipIndex}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      {/* validated feedback response block */}
      {feedback_same && item?.bot?.suggested_questions != null && item?.bot?.answer_type == anwerTypes.Validated && item?.bot?.feedback_type == FeedbackValues.DISLIKE && (
        <div className="p-3 mb-2 bg-[var(--neutral-white)] w-fit max-w-[77.5%] text-[16px] text-[color:var(--almost-black)] leading-5 rounded-t-lg rounded-br-lg">
          {validatedDislikeMessage[selectedLanguage]}
        </div>
      )}
    {/* same feedback answer to both yes and no */}
      {!feedback_same && item?.bot?.feedback_type != null && 
          (item?.bot?.feedback_type === FeedbackValues.DISLIKE || item?.bot?.feedback_type === FeedbackValues.LIKE) && (
            <div className="p-3 mb-2 bg-[var(--neutral-white)] w-fit max-w-[77.5%] text-[16px] text-[color:var(--almost-black)] leading-5 rounded-t-lg rounded-br-lg">
              {validatedSameMessage[selectedLanguage]}
            </div>
      )}
    </div>
  );
};

export default BotChatBlock;
