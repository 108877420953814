export const SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
export const registerApi = SERVER_URL + "api/register";
export const loginApi = SERVER_URL + "api/login";
export const resetPasswordApi = SERVER_URL + "api/resetPassword";
export const updateProfileApi = SERVER_URL + "api/updateProfile";
export const pollyTokenApi = SERVER_URL + "api/pollyToken";
export const verifyPollyTokenApi = SERVER_URL + "api/verifyPollyToken";
export const transcribeApi = SERVER_URL + "api/transcribe";
export const fetchDataApi = SERVER_URL + "api/fetch";
export const submitApi = SERVER_URL + "api/submit";
export const chatFeedbackApi = SERVER_URL + "api/chat_feedback";
export const getUserDataApi = SERVER_URL + "api/getUserData";
export const starBotAnswerApi = SERVER_URL + "api/starred";
export const generateAudioApi = SERVER_URL + "api/generate_audio";
