import React, { useEffect, useState } from "react";
import "./Disclaimer.css";
const Disclaimer = ({  project,selectedLanguage}) => {
    const [constants, setConstants] = useState(null);
   useEffect(() => {
    const loadConstants = async () => {
      try {
        const module = await import(`../../../Constants/${project}/ChatConstants`);
        setConstants(module);
      } catch (error) {
        console.error("Error loading constants:", error);
      }
    };

    loadConstants();
  }, [project]);

  if (!constants) {
    return <div>Loading...</div>; // Display a loading message while constants are being fetched
  }

  const { disclaimer } = constants;
  return (
    <div className='w-[65vw] rounded-md border border-[var(--disclaimer-2)] bg-[var(--disclaimer-3)] p-3'>
      <div className='text-[var(--disclaimer-1)] text-sm font-normal leading-4'>
        <span className='inline-flex items-start justify-center text-center'>
        <img src={"/Assets/Images/lock.svg"} alt='lock'/>
          {disclaimer[selectedLanguage]}
          {/* Lorem ipsum dolor sit amet
          One more line of something text we need to figure out, it should end here. */}
        </span>
      </div>
    </div>
  )
}

export default Disclaimer