import "./App.css";
import "./color-constants.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChatBody from "./Core/Components/ChatBody/ChatBody";
import { useEffect, useRef, useState } from "react";
import { langaugeCode } from "./Constants/Language-Constants";
import Introduction from "./Core/Components/Introduction/Introduction";
import SignupComponent from "./Core/Components/Auth/SignupComponent/SignupComponent";
import LoginComponent from "./Core/Components/Auth/LoginComponent/LoginComponent";
import Guide from "./Core/Components/Guide/Guide";
import Consent from "./Core/Components/Consent/Consent";
import Device_readiness_Consent from "./Core/Components/Device_readiness_Consent/Device_readiness_Consent";
import Device_readiness_Intro from "./Core/Components/Device_readiness_Intro/Device_readiness_Intro";
import Device_readiness_Form from "./Core/Components/Device_readiness_Form/Device_readiness_Form";
import Device_readiness_End from "./Core/Components/Device_readiness_End/Device_readiness_End";
import Device_readiness_Record from "./Core/Components/Device_readiness_Record/Device_readiness_Record";
import NetworkNotification from "./Shared/Components/NetworkNotification/NetworkNotification";
import serviceWorkerRegistration  from './serviceWorkerRegistration';
function App() {
  const [selectedLanguage, setSelectedLanguage] = useState(
    process.env.REACT_APP_DEFAULT_LANGUAGE
  );
  const [textSize, setTextSize] = useState(1); // Initial text size in rem
  const audioRef = useRef();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOnlinePopup, setShowOnlinePopup] = useState(false);
  const [isTryingToConnect, setIsTryingToConnect] = useState(false);
  const [showStatusBar, setShowStatusBar] = useState(false);
  const project = process.env.REACT_APP_PROJECT;

  const decreaseTextSize = (event) => {
    event.preventDefault();
    setTextSize((prevSize) => prevSize - 0.125);
  };

  const increaseTextSize = (event) => {
    event.preventDefault();
    setTextSize((prevSize) => prevSize + 0.125);
  };
  // useEffect(() => {
  //   const onUpdate = registration => {
  //     if (window.confirm('New version available! Would you like to update?')) {
  //       const worker = registration.waiting;
  //       if (worker) {
  //         worker.postMessage({ action: 'skipWaiting' });
  //         worker.addEventListener('statechange', event => {
  //           if (event.target.state === 'activated') {
  //             window.location.reload(true);
  //           }
  //         });
  //       }
  //     }
  //   };

  //   serviceWorkerRegistration({ onUpdate });
  // }, []);


  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    }
  }, []);

  useEffect(() => {
    if (isOnline) {
      const timer = setTimeout(() => {
        setShowOnlinePopup(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isOnline]);

  useEffect(() => {
    if (showOnlinePopup || isTryingToConnect) {
      setShowStatusBar(true);
    } else {
      setShowStatusBar(false);
    }
  }, [showOnlinePopup, isTryingToConnect]);

  const handleOnline = () => {
    setIsOnline(true);
    setShowOnlinePopup(true);
    setIsTryingToConnect(false);
  };

  const handleOffline = () => {
    setIsOnline(false);
    setShowOnlinePopup(true);
  };

  const tryToReconnect = () => {
    setIsTryingToConnect(true);
    setTimeout(() => {
      if (navigator.onLine) {
        handleOnline();
      } else {
        setIsTryingToConnect(false);
      }
    }, 2000);
  };

  return (
    <div className="h-screen w-screen overflow-hidden">
      <Router>
        <Routes>
          <Route
            path="/chat"
            element={
              <ChatBody
                project={project}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                textSize={textSize}
                decreaseTextSize={decreaseTextSize}
                increaseTextSize={increaseTextSize}
                audioRef={audioRef}
              />
            }
          ></Route>
          <Route
            path="/"
            element={
              <Introduction
                project={project}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                audioRef={audioRef}
              />
            }
          ></Route>
          <Route path="/login" element={<LoginComponent project={project} selectedLanguage={selectedLanguage} audioRef={audioRef} />}></Route>
          <Route path="/signup" element={<SignupComponent project={project} audioRef={audioRef} />}></Route>
          <Route
            path="/guide"
            element={<Guide project={project} selectedLanguage={selectedLanguage} audioRef={audioRef} />}
          ></Route>
          <Route
            path="/consent"
            element={<Consent project={project} selectedLanguage={selectedLanguage} audioRef={audioRef} />}
          ></Route>
          <Route
            path="/device_readiness/consent"
            element={<Device_readiness_Consent project={project} selectedLanguage={selectedLanguage} audioRef={audioRef} />}
          ></Route>
          <Route
            path="/device_readiness/intro"
            element={<Device_readiness_Intro project={project} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} audioRef={audioRef} />}
          ></Route>
          <Route
            path="/device_readiness/form"
            element={<Device_readiness_Form project={project} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} audioRef={audioRef} />}
          ></Route>
          <Route
            path="/device_readiness/thanku"
            element={<Device_readiness_End project={project} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} audioRef={audioRef} />}
          ></Route>
          <Route
            path="/device_readiness/record"
            element={<Device_readiness_Record project={project} selectedLanguage={selectedLanguage} />}
          ></Route>





        </Routes>
      </Router>
      {(showOnlinePopup || isTryingToConnect) && <NetworkNotification project={project} isOnline={isOnline} isTryingToConnect={isTryingToConnect} tryToReconnect={tryToReconnect} />}
    </div>
  );
}

export default App;




