import qs from "query-string";

export const fetchApi = async (url, method = "GET", body = {}, headers = new Headers(), params = {}, failureRedirect = true) => {
  const obj = { method, headers, body };

  if (method === 'DELETE') {
    url = qs.stringifyUrl({ url: url, query: body });
    delete obj.body;
  }

  if (method === 'GET') {
    delete obj.body;
  }

  if (JSON.stringify(params) !== JSON.stringify({})) {
    var data = params;
    var newURLSearchParams = url + "?";
    for (var k in data) {
      newURLSearchParams = newURLSearchParams + encodeURIComponent(k) + "=" + encodeURIComponent(data[k]) + "&";
    }
    newURLSearchParams = newURLSearchParams.slice(0, -1);
    try {
      const response = await fetch(newURLSearchParams, obj);

      if (response.ok) {
        const contentLength = response.headers.get('Content-Length');
        const blob = await response.blob();

        // Check if Content-Length header is present and matches the blob size
        if (contentLength && parseInt(contentLength, 10) !== blob.size) {
            throw new Error('Content length mismatch: expected ' + contentLength + ' but got ' + blob.size);
        }
        if (response.headers.get("Content-Type").includes("application/json")) {
          return await response.json();
        } else {
          return response;
        }
      } else {
        handleApiFailure(failureRedirect);
        return { error: await response.text() };
      }
    } catch (e) {
      handleApiFailure(failureRedirect);
      return { error: e.message };
    }
  }

  try {
    const response = await fetch(url, obj);

    if (response.ok) {
      if (response.headers.get("Content-Type").includes("application/json")) {
        return await response.json();
      } else {
        return response;
      }
    } else {
      handleApiFailure(failureRedirect);
      return { error: await response.text() };
    }
  } catch (e) {
    console.error("error", e);
    handleApiFailure(failureRedirect);
  }
};

const handleApiFailure = (failureRedirect) => {
  if(failureRedirect) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login';
  }

};
