import React, {useState} from "react";
import "./SideMenu.css";
import MenuOptions from "../../../Shared/Components/MenuOptions/MenuOptions";
import { sideMenu } from "../../../Constants/MenuConstants";
import { fontSizeLabel, languageLabel } from "../../../Constants/Language-Constants";
import { useNavigate } from "react-router-dom";
import HelpDialog from "../../../Shared/Components/HelpDialog/HelpDialog";
import LogoutDialog  from "../../../Shared/Components/LogoutDialog/LogoutDialog";
const SideMenu = ({
  project,
  userData,
  toggleMenu = () => {},
  selectedLanguage,
  setSelectedLanguage = () => {},
  textSize,
  decreaseTextSize = () => {},
  increaseTextSize = () => {}
}) => {
  const navigate = useNavigate();
  const toggleLanguage = (e) => {
    setSelectedLanguage(e);
  };
  const hide_lang_button = process.env.REACT_APP_HIDE_LANG_BUTTON === 'true';
  const [openDialog, setOpenDialog] = useState(false);
  const styles = {
    nameText: {
      fontSize: `${textSize + 0.5}rem`,
    },
    infoText: {
      fontSize: `${textSize + 0.125}rem`,
    },
    readText: {
      fontSize: `${textSize - 0.25}rem`,
    },
  };
  const toggleLogout = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");

    // Implement your logout logic here
    setOpenDialog(false);
  };


  // const handleLogout = () => {
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   navigate("/login");
  // };
  return (
    <div className="outer-container">
      <div className="overlay"></div>
      <div className="inner-container">
        {/* Content of the inner div goes here */}
        <div className="px-6 py-4 userDiv">
          <div className="flex justify-between items-center">
            <div
              className={`font-semibold text-[color:var(--black)]`}
              style={styles.nameText}
            >
              {userData?.name}
            </div>
            <div onClick={toggleMenu}>
              <img src="/Assets/Images/close.svg" alt="close" />
            </div>
          </div>
          <div
            className={`font-normal text-[color:var(--black)] opacity-50`}
            style={styles.infoText}
          >
            {userData?.mobile} <br />
            {userData?.location?.district}, {userData?.location?.state}
          </div>
        </div>
        {sideMenu.map((itm, idx) => (
          <MenuOptions
            key={idx}
            value={itm.value[languageLabel[selectedLanguage]]}
            icon={itm.icon}
            textSize={textSize}
          />
        ))}
        <div className="bottomSectionDiv">
          <div className="px-8">
            <div className="text-[color:var(--dark-neutral-2)] text-sm">
              {selectedLanguage === "en"
                ? "Change Text size"
                : "टेक्स्ट का साइज  बदलें"}
            </div>
            <div className="changeSizeBtnDiv">
              <button
                onClick={(e) => decreaseTextSize(e)}
                className={
                  textSize === 0.875 ? "pointer-events-none opacity-30" : ""
                }
              >
                {fontSizeLabel[selectedLanguage]}-
              </button>
              <div className="h-[100%] bg-[var(--light-neutral-3)] w-px" />
              <button
                onClick={(e) => increaseTextSize(e)}
                className={
                  textSize === 1.125
                    ? "pointer-events-none opacity-30 font-medium text-lg"
                    : "font-medium text-lg"
                }
              >
                {fontSizeLabel[selectedLanguage]}+
              </button>
            </div>
            {!hide_lang_button &&<div className="lngBtnDiv">
              <button
                className={`flex justify-center h-[100%] w-[50%] rounded-lg py-1.5 ${
                  selectedLanguage === "hi" ? "selectedLngBtn" : "normalBtn"
                }`}
                style={styles.infoText}
                onClick={() => toggleLanguage("hi")}
              >
                {languageLabel["hi"]}
              </button>
              <button
                className={`flex justify-center h-[100%] w-[50%] rounded-lg py-1.5 ${
                  selectedLanguage === "en" ? "selectedLngBtn" : "normalBtn"
                }`}
                style={styles.infoText}
                onClick={() => toggleLanguage("en")}
              >
                {languageLabel["en"]}
              </button>
            </div>}
          </div>
          <div className="bg-[var(--light-neutral-6)]">
            <div className="px-8 py-4">
              {/* <div
                className={`flex gap-3 text-[color:var(--red-button)] pb-4 items-center`}
                style={styles.infoText}
              >
                <img
                  src={"/Assets/Images/withdrawIcn.svg"}
                  alt="withdraw consent"
                />
                {selectedLanguage === "en"
                  ? "Withdraw consent"
                  : "सहमति वापस लें"}
              </div> */}
              {/* <button
                className={`flex gap-3 text-[color:var(--red-button)] py-4 items-center`}
                style={styles.infoText}
                onClick={handleConfirmLogout}
              >
                <img src={"/Assets/Images/logoutIcn.svg"} alt="logout" />
                {selectedLanguage === "en" ? "Withdraw Consent" : "सहमति वापस लें"}
              </button> */}

              <button
                className={`flex gap-3 text-[color:var(--red-button)] py-4 items-center`}
                style={styles.infoText}
                onClick={toggleLogout}
              >
                <img src={"/Assets/Images/logoutIcn.svg"} alt="logout" />
                {selectedLanguage === "en" ? "Log out" : "लॉग आउट"}
              </button>
              <LogoutDialog
                project={project}
                open={openDialog}
                handleClose={handleClose}
                handleConfirm={handleConfirmLogout}
                message="Are you sure you want to log out?"
                selectedLanguage= {selectedLanguage}
              />

              {/* <div className={`opacity-60 py-1`} style={styles.readText}>
                Read more about{" "}
                <span className="text-[var(--link-color)] underline">
                  Asha AI Assistant
                </span>
                <br />
                Version 3.6 released on 13 Feb, 2023
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
